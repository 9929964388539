import { configureStore } from '@reduxjs/toolkit'
import {certificationSlice} from './slices/certification'

export const store = configureStore({
  reducer: {
    certification: certificationSlice.reducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch