import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import IndustryTypes from "components/IndustryTypes/IndustryTypes";
import NumberOfEmployees from "components/NumberOfEmployees/NumberOfEmployees";
import {NUMBER_OF_EMPLOYEES} from "data/listings";
import {ExperiencesDataType, StayDataType, TaxonomyType} from "data/types";
import {FC} from "react";

export interface PersonRegisterPageProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: ExperiencesDataType[] = NUMBER_OF_EMPLOYEES.filter(
  (_, i) => i < 8
);

const industryTypes: TaxonomyType[] = [
  {
    id: "1",
    href: "/business-register",
    name: "Energía renovable",
    taxonomy: "category",
    count: 510,
    thumbnail:
      "https://www.elsolidario.org/wp-content/uploads/2020/12/energiasrenovables-800x445.jpg",
  },
  {
    id: "2",
    href: "/business-register",
    name: "Minería",
    taxonomy: "category",
    count: 1781,
    thumbnail:
      "https://globalindustries.mx/wp-content/uploads/2020/09/1500x844_contratos_comerciales_mineria-696x392.jpg",
  },
  {
    id: "2",
    href: "/business-register",
    name: "Seguridad privada",
    taxonomy: "category",
    count: 187,
    thumbnail:
      "https://www.segurilatam.com/wp-content/uploads/sites/5/2020/09/vigilantes-de-seguridad-privada.jpg",
  },
  {
    id: "2",
    href: "/business-register",
    name: "Construcción",
    taxonomy: "category",
    count: 698,
    thumbnail:
      "https://cdn.redshift.autodesk.com/sites/7/2021/01/Lean-construction-infog-header.jpg",
  },
  {
    id: "2",
    href: "/business-register",
    name: "Tecnología",
    taxonomy: "category",
    count: 2500,
    thumbnail:
      "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_1875/https://computacioninteractiva.com/wp-content/uploads/2019/07/INVERTIR-EN-TECNOLOGIA.png",
  },
  {
    id: "2",
    href: "/business-register",
    name: "Mejora continua",
    taxonomy: "category",
    count: 7844,
    thumbnail:
      "https://altertecnia.com/wp-content/uploads/kaizen-mejora-continua.jpg",
  },
];

const numberOfEmployes: TaxonomyType[] = [
  {
    id: "1",
    href: "/business-register",
    name: "Menos de 50 personas",
    taxonomy: "category",
    thumbnail:
      "https://ak.picdn.net/shutterstock/videos/26572055/thumb/10.jpg",
  },
  {
    id: "2",
    href: "/business-register",
    name: "Más de 50 personas",
    taxonomy: "category",
    thumbnail:
      "http://www.clker.com/cliparts/u/R/Q/2/E/U/crowd-black-and-white.svg",
  }
];

const BusinessRegisterPage: FC<PersonRegisterPageProps> = ({}) => {

  return (
    <div className="container relative space-y-24 mb-24 lg:space-y-20 lg:mb-32">
      <IndustryTypes
        categories={industryTypes}
        uniqueClassName="IndustryTypes"/>


      <NumberOfEmployees
        categories={numberOfEmployes}
        uniqueClassName="NumberOfEmployees"/>
    </div>
  );
};

export default BusinessRegisterPage;
