import React, {Fragment, useEffect, useState} from "react";
import {Popover, Transition} from "@headlessui/react";
import {FC} from "react";
import ClearDataButton from "./ClearDataButton";
import Checkbox from "../../shared/Checkbox/Checkbox";
import {useCollection} from "react-firebase-hooks/firestore";
import {db} from "../../firebase/config";
import {query, collection, where} from "firebase/firestore";
import { Oval } from "react-loader-spinner";
import {Category} from '../../interfaces/category';
import { useCategory } from '../../hooks/useCategory';

export interface CategoriesInputProps {
  fieldClassName?: string;
}

export const CategoriesInput : FC<CategoriesInputProps>= ({
  fieldClassName = '[ nc-hero-field-padding ]',
}) => {
  const [categoriesSelecteds, setCategoriesSelecteds] = useState<Category[]>([]);
  const {categories, formatCategories} = useCategory()
  const [categorySnapshot, loading] = useCollection(
    query(collection(db, 'categories'), where('trash', '==', false)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (!categorySnapshot) return;
    formatCategories(categorySnapshot.docs);
  }, [categorySnapshot]);

  const handleCategoryCheckboxChange = (value: boolean, categorySelected: Category) => {
    if (value) {
      setCategoriesSelecteds([...categoriesSelecteds, categorySelected]);
    } else {
      setCategoriesSelecteds(categoriesSelecteds.filter(category => category.key !== categorySelected.key));
    }
  }

  return (
    <Popover className='flex relative [ nc-flex-1 ]'>
      {({open}) => (
        <>
          <Popover.Button
            className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer ${
              open ? 'nc-hero-field-focused' : ''
            }`}
          >
            <div className='text-neutral-300 dark:text-neutral-400'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='nc-icon-field'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z'
                />
              </svg>
            </div>
            <div className='flex-grow'>
              <span className='block xl:text-lg font-semibold'>
                {categoriesSelecteds.length || ''} Categorías
              </span>
              <span className='block mt-1 text-sm text-neutral-400 leading-none font-light'>
                {categoriesSelecteds ? 'Categorías' : 'Seleccionar categoría'}
              </span>
              {!!categoriesSelecteds && open && (
                <ClearDataButton
                  onClick={() => {
                    setCategoriesSelecteds([]);
                  }}
                />
              )}
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl'>
              {loading ? (
                <div className='flex justify-center'>
                  <Oval height='30' width='30' color='grey' ariaLabel='Cargando...' />
                </div>
              ) : (
                categories.map((item, index) => (
                  <div key={item.key}>
                    <Checkbox
                      name={index.toString()}
                      label={item.name}
                      subLabel={item.description}
                      defaultChecked = {categoriesSelecteds.some(category => category.key === item.key)}
                      onChange={value => handleCategoryCheckboxChange(value, item)}
                    />
                  </div>
                ))
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
