import React, {FC, useState, useEffect} from "react";
import CertificationCard from "components/StayCard/CertificationCard";
import {DEMO_STAY_LISTINGS} from "data/listings";
import {StayDataType} from "data/types";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { Certification } from "interfaces/certification";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { Certifier } from "interfaces/certifier";
import { collection, where, query } from 'firebase/firestore';
import {db} from '../../firebase/config';

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  certifications: Certification[];
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
                                                                 className = "",
                                                                 certifications
                                                               }) => {
  const [certifiers, setCertifiers] = useState<Certifier[]>([]);
  const [certifiersSelectedKeys, setCertifiersSelectedKeys] = useState<string[]>([]);
  const [filteredCertifications, setFilteredCertifications] = useState<Certification[]>([]);
  const [certifiersSnapshot, loading, error] = useCollectionOnce(
    query(collection(db, "certifiers"), where("trash", "==", false))
  );

  useEffect(() => {
    if (!certifiersSnapshot || !certifiersSnapshot.docs.length || loading || !!error) return;
    const formattedCertifiers = certifiersSnapshot.docs.map(certifier => ({
      ...certifier.data(),
      key: certifier.id
    } as Certifier));

    setCertifiers(formattedCertifiers);
  }, [certifiersSnapshot, loading]);

  useEffect(() => {
    setFilteredCertifications([...certifications]);
  }, [certifications])

  useEffect(() => {
    filterCertifications();
  }, [certifiersSelectedKeys]);

  const filterCertifications = () => {
    if (certifiersSelectedKeys.length) {
      setFilteredCertifications(certifications.filter(certification => (
        certifiersSelectedKeys.some(key => key == (certification.certifier as Certifier).key)
      )));
    } else {
      setFilteredCertifications(certifications as Certification[]);
    }
  }

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 />

      <div className="mb-8 lg:mb-11">
        <TabFilters
          setCertifiersSelectedKeys={setCertifiersSelectedKeys}
          certifiers={certifiers}
        />
      </div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {filteredCertifications.map((certification: any, index) => (
          <CertificationCard
            key={certification.key}
            certification={certification}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
