import React, {FC, useEffect, useState} from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import {Helmet} from "react-helmet";
import { Certification } from "interfaces/certification";
import {doc, getDoc} from 'firebase/firestore';
import {db} from '../../firebase/config';
import { useAppDispatch, useAppSelector } from "store";
import { getAllCertifications, setFormatted } from "store/slices/certification";

export interface CertificationsProps {
  className?: string;
}

const CertificationsPage: FC<CertificationsProps> = ({ className = "" }) => {
  const [currentCertifications, setCurrentCertifications] = useState([] as Certification[]);
  const {certifications, fetched, formatted} = useAppSelector(state => state.certification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!fetched) dispatch(getAllCertifications());
  }, []);

  useEffect(() => {
    if (!!fetched && !formatted) formatCertifications(certifications);
  }, [fetched, formatted])

  const formatCertifications = async (certifications: Certification[]) => {
    const formattedCertifications = await Promise.all(
      certifications.map(async (certification: any) => {
        const industryTypeRef = doc(db, 'industryTypes', certification.industryType);
        const industryTypeSnapshot = await getDoc(industryTypeRef);
        const industryType = {
          ...industryTypeSnapshot.data(),
          key: industryTypeSnapshot.id
        };

        const certifierRef = doc(db, 'certifiers', certification.certifier);
        const certifierSnapshot = await getDoc(certifierRef);
        const certifier = {
          ...certifierSnapshot.data(),
          key: certifierSnapshot.id
        };

        const categoryRef = doc(db, 'categories', certification.category);
        const categorySnapshot = await getDoc(categoryRef);
        const category = {
          ...categorySnapshot.data(),
          key: categorySnapshot.id
        };
        return {
          ...certification,
          industryType,
          certifier,
          category
        }
      })
    )

    setCurrentCertifications(formattedCertifications);
    dispatch(setFormatted(true))
  }

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>Certificaciones</title>
      </Helmet>

      <div className="container relative overflow-hidden">
        <SectionGridFilterCard certifications={currentCertifications} className="pb-24 lg:pb-32" />
      </div>
    </div>
  );
};

export default CertificationsPage;
