import React, {FC} from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import {Link} from "react-router-dom";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
                                                                 className = "",
                                                                 rightImg = rightImgDemo,
                                                               }) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Certificación de persona
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Una certificación es una herramienta para validar el dominio y conocimiento de una materia, una tecnología o un conjunto de competencias.
        </span>
        <ButtonPrimary className="mt-6 sm:mt-11">
          <Link to='/person-register'>Certificar persona</Link>
        </ButtonPrimary>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg}/>
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
