import {useState} from 'react';
import { Category } from '../interfaces/category';

export const useCategory = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  const formatCategories = (docs: any[]) => {
    const categoriesWithId = docs.map(doc => ({
      ...doc.data(),
      key: doc.id
    }));

    setCategories(categoriesWithId);
  }

  return {
    categories,
    formatCategories
  }
}