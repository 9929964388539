import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { AVAILABLE_JOBS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Badge from "shared/Badge/Badge";
import { StayDataType } from "data/types";

export interface PropertyCardHProps {
  className?: string;
  data?: StayDataType;
  index?: number
}


const FILTERED_JOBS = AVAILABLE_JOBS[0];

const PropertyCardH: FC<PropertyCardHProps> = ({
  className = "",
  data = FILTERED_JOBS,
  index
}) => {
  const {
    galleryImgs,
    title,
    href,
    like,    
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={galleryImgs}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
        />        
      </div>
    );
  };
  
  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">         
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{title}</span>
            </h2>
          </div>
          <div>
            Duración: <span className="text-muted">45 horas</span>
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            <StartRating reviewCount={reviewCount} point={reviewStart} />
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
              {`${price},000`}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <Link
        to={`${href}/${index}`}
        className="h-full w-full flex flex-col sm:flex-row sm:items-center"
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
      <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        isLiked={like}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      />
    </div>
  );
};

export default PropertyCardH;
