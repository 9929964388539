import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Inicio"
  },
  {
    id: ncNanoId(),
    href: "/services",
    name: "Servicios"
  },
  {
    id: ncNanoId(),
    href: "/billing",
    name: "Facturación"
  },
];
