import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Page} from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import SiteHeader from "containers/SiteHeader";
import PageSignUp from "../containers/PageSignUp/PageSignUp";
import PageLogin from "../containers/PageLogin/PageLogin";
import CertificationsPage from "containers/Certifications/CertificationsPage";
import CertificationsDetailsPage from "../containers/ListingDetailPage/CertificationsDetailsPage";
import { MyProfile } from "containers/MyProfile/MyProfile";
import PersonRegisterPage from "containers/PersonRegisterPage/PersonRegisterPage";
import BusinessRegisterPage from "containers/BusinessRegisterPage/BusinessRegisterPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/my-profile", component: MyProfile },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/certifications", component: CertificationsPage },
  { path: "/certification-details/:id", component: CertificationsDetailsPage },
  { path: "/person-register", component: PersonRegisterPage },
  { path: "/business-register", component: BusinessRegisterPage }
];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
