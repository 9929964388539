import React from "react";
import {Link} from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      <img className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
           src={img}
           alt="Logo"
      />
    </Link>
  );
};

export default Logo;
