import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetched: false,
  isLoading: false,
  certifications: [],
  formatted: false
}

export const certificationSlice = createSlice({
  name: 'certification',
  initialState,
  reducers: {
    startLoadingCertifications: (state) => {
      state.isLoading = true;
    },

    finishLoadingCertifications: (state) => {
      state.isLoading = false;
    },

    setCertifications: (state, action) => {
      state.isLoading = false;
      state.fetched = true;
      state.formatted = false;
      state.certifications = action.payload;
    },

    setFormatted: (state, action) => {
      state.formatted = action.payload;
    }
  }
});

export const { setCertifications, startLoadingCertifications, setFormatted } = certificationSlice.actions;