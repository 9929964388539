import React, {FC} from "react";
import rightImgPng from "images/company-cert.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
                                                           className = "lg:py-14",
                                                           rightImg = rightImgPng,
                                                           type = "type1",
                                                         }) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="mx-auto h-100 w-100">
        <NcImage src={rightImg}/>
      </div>
      <div
        className={`ml-auto flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5`}>
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          Certificaciones
        </span>

        <h2 className="font-semibold text-4xl mt-5">Certificar empresa</h2>

        <ul className="space-y-10 mt-10">
          <li className="space-y-4">
            <Badge name="Concepto"/>
            <span className="block text-xl font-semibold">
              ¿Qué es una certificación?
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Una certificación es un procedimiento por el cual un organismo calificado avala por escrito que un producto, proceso o servicio cumple con requisitos y normas específicas que respaldan su calidad.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Beneficios"/>

            <span className="block text-xl font-semibold">
              ¿Qué obtendré al certificar mi empresa?
            </span>

            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Se generará confianza en tu producto o servicio.
            </span>

            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Aumentarás tu competitividad al cumplir con estándares medibles.
            </span>

            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Se proporcionará reconocimiento gracias a las habilidades y conocimientos adquiridos.
            </span>

            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Demostrarás compromisos con los estándares superiores de la industria.
            </span>

            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Tu empresa aumentará su prestigio profesional.
            </span>

            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Aprenderás alternativas innovadoras para tu empresa.
            </span>

            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
             Hay mayor flexibilidad con respecto a la colaboración y el comercio entre empresas certificadas.
            </span>
          </li>
        </ul>

        <ButtonPrimary className="mt-6 sm:mt-11">
          <Link to='/business-register'>Certificar empresa</Link>
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
