import React, {FC} from "react";
import {DEMO_STAY_LISTINGS} from "data/listings";
import {StayDataType} from "data/types";
import {Link} from "react-router-dom";
import { Certification } from "interfaces/certification";
import { Category } from '../../interfaces/category';
import { Certifier } from '../../interfaces/certifier';
import { IndustryType } from "interfaces/industry-type";
import {UnitTimeLabel} from '../../labels/unit-time.label'

export interface StayCardProps {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
  index?: number;
  certification: Certification
}

const DEMO_DATA: any = DEMO_STAY_LISTINGS[0];

const CertificationCard: FC<StayCardProps> = ({
                                                size = "default",
                                                className = "",
                                                certification
                                              }) => {

  const category: Category = certification.category as Category;
  const certifier: Certifier = certification.certifier as Certifier;
  const industryType: IndustryType = certification.industryType as IndustryType;

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {category.name}
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}>
              <span>{certification.title}</span>
            </h2>
          </div>
          <div className="flex w-full items-center justify-content-between text-neutral-500 dark:text-neutral-400 text-sm space-x-4">
            <span>{industryType.name}</span>
            <span>{certification.duration} {UnitTimeLabel[certification.durationUnitOfTime]}</span>
          </div>
          <div className="flex w-full items-center justify-content-between text-neutral-500 dark:text-neutral-400 text-sm space-x-4">
            <span>{certifier.name}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <Link to={``}>
        {renderContent()}
      </Link>
    </div>
  );
};

export default CertificationCard;
