import {initializeApp} from "firebase/app";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAPpXOlWWviad3VlRZRvBobOXn15UuA4dU",
  authDomain: "certificame.firebaseapp.com",
  projectId: "certificame",
  storageBucket: "certificame.appspot.com",
  messagingSenderId: "1079507236422",
  appId: "1:1079507236422:web:5d0e5ab89c3d37c31fbc47",
  measurementId: "G-FS14TWEMGD"
};

const firebaseApp = initializeApp(firebaseConfig);
let auth = getAuth(firebaseApp);
let db = getFirestore(firebaseApp);
let functions = getFunctions(firebaseApp);

// eslint-disable-next-line no-restricted-globals
if (window.location.hostname === "localhost") {
  connectAuthEmulator(auth, 'http://localhost:9099/');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export {firebaseApp, auth, db, functions};