import { AppDispatch } from '../../store';
import { setCertifications, startLoadingCertifications } from './certificationSlice';
import {db} from '../../../firebase/config';
import {collection, where, query, getDocs, doc, getDoc} from 'firebase/firestore';


export const getAllCertifications = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoadingCertifications());
    const certificationsRef = collection(db, 'certifications');

    const certificationsSnapshot = await getDocs(query(certificationsRef, where('trash', '==', false)));
    const certifications = certificationsSnapshot.docs.map(doc => ({
      ...doc.data(),
      key: doc.id
    }));


    if (!!certifications) {
      const formattedCertifications = certifications.map((certification: any) => {
        const [,,,,,,industryTypeKey] = certification.industryType._key.path.segments;
        const [,,,,,,certifierKey] = certification.certifier._key.path.segments;
        const [,,,,,,categoryKey] = certification.category._key.path.segments;

        return {
          ...certification,
          industryType: industryTypeKey,
          certifier: certifierKey,
          category: categoryKey
        }
      });

      dispatch(setCertifications(formattedCertifications));
    }
  }


}