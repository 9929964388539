import React, {FC, useEffect} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import logo from 'images/logos/nomal/logo-black-lg.png';
import { useAppDispatch, useAppSelector } from "store";
import {getAllCertifications} from '../../store/slices/certification';

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({className = ""}) => {
  const dispatch = useAppDispatch();
  const {fetched} = useAppSelector(state => state.certification);

  useEffect(() => {
    if (fetched) return;
    dispatch(getAllCertifications());
  }, []);

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero">
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div
          className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <img
            className={'w-full'}
            src={logo}
            alt="logo"/>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          Encuentra cerca de ti los mejores servicios de capacitación, evaluación y certificación.
          </span>
          <ButtonPrimary>Buscar</ButtonPrimary>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={'https://certificame.mx/wp-content/uploads/2022/03/certificame_bg.jpg'} alt="hero"/>
        </div>
      </div>

      <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm/>
      </div>
    </div>
  );
};

export default SectionHero;
