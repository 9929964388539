import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Check, UploadFile, Warning, FindInPage, CheckCircle } from '@mui/icons-material';

export const MyProfile = () => {
  const [profileSections, setInfo] = useState([
    {
      title: 'Información general',
      isComplete: false,
      firstHeaderLabel: 'Requisitos',
      details: [
        {
          requirements: 'Imagen corporativa',
          isUploaded: false,
          procedurePlace: ''
        },
        {
          requirements: 'Registro de marca',
          isUploaded: false,
          procedurePlace: ''
        },
        {
          requirements: 'RFC',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Datos de contacto',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Constancia de situación fiscal',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Solicitud en hoja membretada dirigida a la agencia municipal de energía y cambio climático',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Solicitud de inscripción al patrón de proveedores',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Registro vigente como proveedor del municipio',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Carta de proveedor autorizado y respaldo de la marca',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Representates',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Garantías que ofrecen por escrito',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Acta constitutiva',
          isUploaded: true,
          procedurePlace: ''
        }
      ]
    },
    {
      title: 'Estructura organizacional',
      isComplete: true,
      firstHeaderLabel: 'Requisitos',
      details: [
        {
          requirements: 'Planeación estratégica (misión, visión y objetivos) y descripción de servicios',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Estructura organizacional (organigrama y estructura orgánica)',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Perfil de puesto',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Diagnóstico de perfiles',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'EC0586.01 Instalación de sistemas fotovoltaicos en residencia, comercio e industria',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'EC1181 Supervisión de sistemas fotovoltaicos en residencia, comercio e industria',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'EC0381.01 Verificación de las condiciones de seguridad e higiene en los centros de trabajo',
          isUploaded: true,
          procedurePlace: ''
        }
      ]
    },
    {
      title: 'Certificados de la empresa',
      isComplete: true,
      firstHeaderLabel: 'Normatividad',
      details: [
        {
          requirements: 'ISO 9001: 2018',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'ISO 14000: 2015',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-001-STPS-1999',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-004-STPS-1999',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-027-STPS-2001',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-017-STPS-2001',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-009-STPS-1999',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-029-STPS-2011',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-035-STPS-2018',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-001-STPS-2018',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'NOM-018-STPS-2011',
          isUploaded: true,
          procedurePlace: ''
        }
      ]
    },
    {
      title: 'Comisión de seguridad e higiene en el trabajo',
      isComplete: true,
      firstHeaderLabel: 'Requisitos',
      details: [
        {
          requirements: 'Acta de constitución',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Programa anual de recorridos',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Diagnóstico de seguridad e higiene en el trabajo',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Programa anual de seguridad e higiene',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Actas de recorridos',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Acciones correctivas',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Acciones preventivas',
          isUploaded: true,
          procedurePlace: ''
        }
      ]
    },
    {
      title: 'Comisión mixta de capacitación, adiestramiento y productividad',
      isComplete: true,
      firstHeaderLabel: 'Requisitos',
      details: [
        {
          requirements: 'Acta de constitución',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Diagnóstico de perfiles',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Diagnóstico de ambiente laboral',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Diagnóstico de salud ocupacional',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Programa de capacitación',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Programa de evaluación de productividad',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Capacitación de personal (DC3, estándar de competencia)',
          isUploaded: true,
          procedurePlace: ''
        },
        {
          requirements: 'Certificación de personal',
          isUploaded: true,
          procedurePlace: ''
        }
      ]
    }
  ]);

  return (
    <>
      <div className='p-5'>
        <div className='border-b-4 border-solid border-red-700 pl-7 pb-1'>
          <h1 className='text-3xl font-semibold'>Mi perfil</h1>
        </div>

        <div className='mt-4'>
          <div className='listingSection__wrap'>
            {profileSections.map(({title, isComplete, firstHeaderLabel, details}, index) => {
              return (
                <div key={index}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography sx={{width: '50%', fontWeight: 'bold', color: 'gray'}}>
                        {title}
                      </Typography>

                      <Typography align={'right'} sx={{width: '50%'}}>
                        <Tooltip title={isComplete ? 'Completo' : 'Incompleto'}>
                          {isComplete ? (
                            <CheckCircle className='text-green-600' />
                          ) : (
                            <Warning className='text-yellow-400' />
                          )}
                        </Tooltip>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='flow-root'>
                        <div className='text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4'>
                          <div
                            className={'p-4  grid grid-cols-2 font-bold border-y-2 border-solid'}
                          >
                            <span>{firstHeaderLabel}</span>
                            <div className='grid grid-cols-3'>
                              <span className='text-center'>Cumple</span>
                              <span className='text-center'>Registrar / Subir documento</span>
                              <span className='text-center'>¿Dónde realizo el trámite?</span>
                            </div>
                          </div>

                          {details.map(({requirements, isUploaded, procedurePlace}, index) => {
                            return (
                              <div key={index}>
                                <div
                                  className={`p-4 rounded-lg items-center grid grid-cols-2 ${
                                    index % 2 ? 'bg-neutral-100 dark:bg-neutral-800' : ''
                                  }`}
                                >

                                  <Typography align={'left'}>{requirements}</Typography>
                                  <div className='grid grid-cols-3'>
                                  <Typography align={'center'}>
                                    {isUploaded ? (
                                      <Check fontSize={'medium'} className='text-green-600' />
                                    ) : (
                                      ''
                                    )}
                                  </Typography>
                                  <Typography align={'center'}>
                                    {isUploaded ? (
                                      <FindInPage fontSize={'large'} />
                                    ) : (
                                      <UploadFile fontSize={'large'} />
                                    )}
                                  </Typography>
                                  <Typography align={'center'}>{procedurePlace}</Typography>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

// #c80404